import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import CaseStudyHeader from "../../../components/pages/work/case-studies/CaseStudyHeader"
import CaseStudyConsultation from "../../../components/pages/work/case-studies/CaseStudyConsultation"
import CaseStudyUserStories from "../../../components/pages/work/case-studies/CaseStudyUserStories"
import CaseStudyWireframes from "../../../components/pages/work/case-studies/CaseStudyWireframes"
import CaseStudyIterations from "../../../components/pages/work/case-studies/CaseStudyIterations"
import CaseStudyTechnologies from "../../../components/pages/work/case-studies/CaseStudyTechnologies"
import CaseStudyResults from "../../../components/pages/work/case-studies/CaseStudyResults"
import Contact from "../../../components/pages/index/Contact"
import CaseStudyChallenges from "../../../components/pages/work/case-studies/CaseStudyChallenges"

const WalletOptimizerMobilePage = ({ pageContext, location }) => {
  const consultationDetails = {
    company: "Wallet Optimizer",
    location: "Pittsburgh, PA",
    industry: "Money Management",
    developmentType: "Mobile App",
    scope: "Design and Full Stack Development",
    businessObjectives:
      "To help low to medium income earners easily manage their finances by budgeting their expenses and weighing them against their income. Wallet Optimizer is targeting 50,000 downloads within the first year of release with revenues of $300 per month from recommended products.",

    desiredOutcomes:
      "The Wallet Optimizer app must be easy to onboard new users, link bank accounts and create budgets. It should display easily readable statistical graphs on the dashboard to show the user's progress. Revenue generating recommendations will not be a focus for the first release. User feedback (NPS Score) of 60-80 is the ad-free app's target.",

    targetMarket: "Low to medium income earners aged 27-35",
    competition: "PocketGuard",
    competitiveAdvantage: "Ease of use",
    timeFrame: "6 Months",
  }

  const userStories = {
    mustHaves: [
      "A simple walkthrough to explain what the app does before signup",
      "A signup screen with name, email and password",
      "Login screen that is presented every time the user opens the app",
      "Forget password screen",
      "Dashboard screen displaying account balance, latest transactions and basic statistics",
      "Calendar screen to show transactions on a daily basis",
      "Spending screen to show money spent vs. budget as well as link to the budgets",
      "Budgets screen to set up new budgets",
      "Expenses screen to see all expenses based on search criteria and allows to sort expenses into budgets",
      "Profile screen which links to account details, adding banks, and showing notifications",
      "Account details edit screen",
      "Add bank account screen",
      "Notifications screen to show any budget alerts",
      "About Wallet Optimizer screen to provide company details and help",
    ],
    niceToHaves: [
      "Refer a friend link",
      "Joint account feature for spouses",
      "Biometric login",
      "Financial advisor recommendation when income and expenses don't balance",
      "Budget recommendations to start a user in their journey",
      "Suspicious activity detection",
      "Bill tracking for unusual activity on bills",
      "Financial planning questionnaire to help set goals",
    ],
    maybeLater: [
      "Video tutorial how to get the most out of the app",
      "Investment integration",
      "Net worth tracking integration",
      "Credit monitoring service integration",
      "Loan tracking integration",
      "Mortgage tracking integration",
      "Mortgage and loan refinance recommendations",
      "Add an AI bot to help users with frequently asked questions",
    ],
  }

  const iterations = {
    iteration1: {
      stories: [
        "Design and development environment setup",
        "Database selection and setup",
        "Asset collection and review",
        "Wireframe review",
      ],
      weeks: "Week 1",
    },
    iteration2: {
      stories: [
        "Database configuration for user login",
        "Account creation screen design & development",
        "Login Screen design & development",
        "Forget password screen design and development",
        "Splash screen design and development",
        "My Information screen design and development",
      ],
      weeks: "Week 2-3",
    },
    iteration3: {
      stories: [
        "Bank Account connection screen design and development",
        "Additional bank account search screen design and development",
        "Database configuration for bank accounts and transactions",
        "Plaid API integration with backend and initial transaction pull",
      ],
      weeks: "Week 4-5",
    },
    iteration4: {
      stories: [
        "Calendar screen design and development",
        "API configuration to pull latest transactions",
      ],
      weeks: "Week 6-7",
    },
    iteration5: {
      stories: [
        "Design and develop Budgets screen",
        "Database and API development for budgets with link to transaction categories",
        "Add budget popover for adding budgets",
        "Spent vs. total budget display block",
      ],
      weeks: "Week 8-9",
    },
    iteration6: {
      stories: [
        "Expenses screen design and development",
        "Transaction search filter design and backend API development",
        "Transaction detailed view design and development",
        "API configuration to pull transaction details",
        "Manually re-assign expense to budget",
      ],
      weeks: "Week 10-11",
    },
    iteration7: {
      stories: [
        "Spending screen design and development",
        "Show spent vs. total budget from budget screen",
        "API development for daily spending",
        "Implement charting library to display daily spending pulled from API",
        "Display top 3 budgets and link to budgets screen",
      ],
      weeks: "Week 12-13",
    },
    iteration8: {
      stories: [
        "Design and develop Dashboard screen",
        "API development to provide overall bank balance",
        "Reuse spending evolution from the Spending screen",
        "Add latest transactions with a day filter and use expenses API for day filter",
        "API development for bank balance history",
        "Implement charting library to display bank balance evolution",
      ],
      weeks: "Week 14-15",
    },
    iteration9: {
      stories: [
        "Messaging centre design & development",
        "Database development for messaging and Socket.io integration",
        "Online user display",
      ],
      weeks: "Week 16-17",
    },
    iteration10: {
      stories: [
        "Notifications screen design and development",
        "Anomaly algorithm development",
        "API endpoint development to trigger calculations and analyze data for ATM fees and inconsistent bills as well as add indicator for notification update",
      ],
      weeks: "Week 18-19",
    },
    iteration11: {
      stories: [
        "Design and develop profile screen",
        "Design and develop About Wallet Optimizer screen",
        "Link to My Information, My Accounts, and My Notifications",
        "Sign out link and API development",
        "Pull notification update and add red dot to notification icon if there are updates",
      ],
      weeks: "Week 20-21",
    },
    iteration12: {
      stories: [
        "Walkthrough screens design and development",
        "Connect all iteration links together",
      ],
      weeks: "Week 22-23",
    },
    iteration13: {
      stories: [
        "Release",
        "Emergency support standby as website is tested with live traffic",
      ],
      weeks: "Week 24",
    },
  }

  const technologies = {
    fullStack: true,
    icons: [
      "HTML5",
      "CSS3",
      "Sass",
      "JavaScript",
      "React Native",
      "Redux",
      "Jest",
      "Node",
      "Mongodb",
      "Git",
    ],
  }

  const challenges = {
    challenge1: {
      challenge:
        "Wallet Optimizer had a large pre-existing and detailed roadmap of features to implement.",
      win: (
        <>
          <p>
            Having a large set of features requests isn't a bad thing but it can
            often be the death of project due to a startup's limited resources.
            The Tactic Apps team advised Wallet Optimizer to start small,
            receive customer feedback and pivot accordingly.
          </p>
          <p>
            Working with the Wallet Optimizer team, we carefully selected the
            user stories for their first release that provided the essence of
            their vision.
          </p>
          <p className="mb-0">
            Through an Agile approach we iterated through their user stories by
            relatively sizing them up so we could provide an initial timeline
            for their first release. This allowed Wallet Optimizer to limit
            their budget for the initial release and launch sooner than
            expected.
          </p>
        </>
      ),
    },
    challenge2: {
      challenge:
        "Wallet Optimizer required complex mathematical formulas to generate valuable notifications to their users.",
      win: (
        <>
          <p>
            Even though coding may seem like a deeply mathematical skill set, it
            rarely involves complex math. Wallet Optimizer had some proprietary
            ideas on how to detect bank account anomalies and we had limited
            time to implement these formulas.
          </p>
          <p className="mb-0">
            Without in house expertise on either side, the Tactic Apps team
            worked with Wallet Optimizer to find an excellent freelancer who
            created all the formulas required and kept the project on track.
          </p>
        </>
      ),
    },
    challenge3: {
      challenge:
        "The initial bank connection API used changed their billing structure and was no longer viable for Wallet Optimizer.",
      win: (
        <>
          <p>
            This issue had the potential to derail the entire project. Without a
            way for customers to log into their bank accounts, the budgeting
            portion of the app would have to all be done manually by customers.
          </p>
          <p className="mb-0">
            After 16+ hours of research, the Tactic Apps team found an API
            provider with a lower starting billing structure that had similar
            data output as the initial choice. With some development overtime,
            the project was able to stay on track.
          </p>
        </>
      ),
    },
    challenge4: {
      challenge:
        "The Wallet Optimizer team had been burned before by another development company and was hesitant to start the project with Tactic Apps",
      win: (
        <>
          <p>
            Unfortunately, this is not an uncommon story. Development hurdles
            often creep up when building complex applications which in turn
            leads to overspent budgets and uncompleted projects.
          </p>
          <p className="mb-0">
            The Tactic Apps team explained the Agile development framework and
            how iterations can be paid for individually. This allows Wallet
            Optimizer to cancel their contract at any point without having to
            spend their entire budget upfront. This is useful in case of
            financial trouble or if things aren't going to plan. Having this
            type of control put the Wallet Optimizer team at ease before and
            during the project.
          </p>
        </>
      ),
    },
    challenge5: {
      challenge:
        "The Wallet Optimizer team had serious security concerns due to the sensitive data contained with their application.",
      win: (
        <>
          <p>
            Even though Tactic Apps has an intense focus on security throughout
            the development cycle, a 100% securely built application is nearly
            impossible. Security standards change constantly and new hacks or
            discovered by the hour.
          </p>
          <p className="mb-0">
            After much discussion, the Tactic Apps team reviewed all the
            security measures taken during the development of the app but for
            long term support, a security specialist would need to be hired on
            the Wallet Optimizer staff. Unfortunately this is the nature of
            applications that deal with highly sensitive data and Wallet
            Optimizer decided to factor this cost into their next funding round.
          </p>
        </>
      ),
    },
  }

  const caseStudyData = {
    numBoxes: {
      developers: "2",
      hourly: "105",
      time: "5",
    },
  }

  const resultsImageNames = {
    results1: "Walkthrough 1",
    results2: "Walkthrough 2",
    results3: "Walkthrough 3",
    results4: "Splash Screen",
    results5: "Signup",
    results6: "Login",
    results7: "Dashboard",
    results8: "Calendar",
    results9: "Spending",
    results10: "Budgets",
    results11: "Add Budget",
    results12: "Expenses",
    results13: "Expense Details",
    results14: "Profile",
    results15: "Add Account",
    results16: "Account Login",
  }

  const resultsImageModalSize = "md"

  return (
    <StaticQuery
      query={graphql`
        {
          hero: file(
            relativePath: {
              eq: "work/mobile/wallet-optimizer/wallet-optimizer-hero.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          deviceDisplay: file(
            relativePath: {
              eq: "work/mobile/wallet-optimizer/wallet-optimizer-devices.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe1: file(
            relativePath: {
              eq: "work/mobile/wallet-optimizer/wireframes/walkthrough.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe2: file(
            relativePath: {
              eq: "work/mobile/wallet-optimizer/wireframes/walkthrough_login.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe3: file(
            relativePath: {
              eq: "work/mobile/wallet-optimizer/wireframes/signup_login.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe4: file(
            relativePath: {
              eq: "work/mobile/wallet-optimizer/wireframes/dashboard_spending.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe5: file(
            relativePath: {
              eq: "work/mobile/wallet-optimizer/wireframes/calendar_budgets.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe6: file(
            relativePath: {
              eq: "work/mobile/wallet-optimizer/wireframes/budgets_add_account.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          wireframe7: file(
            relativePath: {
              eq: "work/mobile/wallet-optimizer/wireframes/account_edit.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 1200, layout: CONSTRAINED)
            }
          }
          results1: file(
            relativePath: {
              eq: "work/mobile/wallet-optimizer/1-walkthrough.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results2: file(
            relativePath: {
              eq: "work/mobile/wallet-optimizer/2-walkthrough.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results3: file(
            relativePath: {
              eq: "work/mobile/wallet-optimizer/3-walkthrough.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results4: file(
            relativePath: {
              eq: "work/mobile/wallet-optimizer/4-splash-screen.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results5: file(
            relativePath: { eq: "work/mobile/wallet-optimizer/5-sign-up.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results6: file(
            relativePath: { eq: "work/mobile/wallet-optimizer/6-login.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results7: file(
            relativePath: { eq: "work/mobile/wallet-optimizer/7-dashboard.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results8: file(
            relativePath: { eq: "work/mobile/wallet-optimizer/8-calendar.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results9: file(
            relativePath: { eq: "work/mobile/wallet-optimizer/9-spending.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results10: file(
            relativePath: { eq: "work/mobile/wallet-optimizer/10-budgets.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results11: file(
            relativePath: {
              eq: "work/mobile/wallet-optimizer/11-add-budget.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results12: file(
            relativePath: { eq: "work/mobile/wallet-optimizer/12-expenses.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results13: file(
            relativePath: {
              eq: "work/mobile/wallet-optimizer/13-expense-details.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results14: file(
            relativePath: { eq: "work/mobile/wallet-optimizer/16-profile.png" }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results15: file(
            relativePath: {
              eq: "work/mobile/wallet-optimizer/14-add-account.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
          results16: file(
            relativePath: {
              eq: "work/mobile/wallet-optimizer/15-account-login.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(width: 380, layout: CONSTRAINED)
            }
          }
        }
      `}
      render={(data) => (
        <Layout>
          <Seo
            title="Wallet Optimizer Mobile App Case Study"
            keywords={[`wallet optimizer mobile app case study`]}
            description="Wallet Optimizer is a mobile application that helps people manage their finances on their smartphone. They required full stack development in React Native."
          />
          <CaseStudyHeader
            image={data.hero.childImageSharp.gatsbyImageData}
            title="Mobile Application Case study"
            subTitle="Wallet Optimizer"
            overlay="none"
            pageContext={pageContext}
            location={location}
            crumbLabel="Wallet Optimizer"
          />
          <main>
            <CaseStudyConsultation
              consultationDetails={consultationDetails}
              deviceImage={data.deviceDisplay}
            />
            <CaseStudyUserStories
              userStories={userStories}
              companyName={consultationDetails.company}
            />
            <CaseStudyWireframes
              wireframeImages={data}
              companyName={consultationDetails.company}
            />
            <CaseStudyIterations
              iterationData={iterations}
              companyName={consultationDetails.company}
            />
            <CaseStudyTechnologies
              technologies={technologies}
              companyName={consultationDetails.company}
              developmentType={consultationDetails.developmentType}
            />
            <CaseStudyChallenges
              challenges={challenges}
              companyName={consultationDetails.company}
            />
            <CaseStudyResults
              caseStudyData={caseStudyData}
              resultsImages={data}
              resultsImageNames={resultsImageNames}
              resultsImageModalSize={resultsImageModalSize}
              companyName={consultationDetails.company}
              developmentType={consultationDetails.developmentType}
            />
            <Contact
              heading={
                <>
                  Let's build your{" "}
                  <span className="text-primary">
                    {consultationDetails.developmentType}
                  </span>{" "}
                  Together
                </>
              }
            />
          </main>
        </Layout>
      )}
    />
  )
}

export default WalletOptimizerMobilePage
